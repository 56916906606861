import { CurrentAndNextDigitalSignageCampaigns } from "../../models/CurrentAndNextDigitalSignageCampaigns";
import Customer from "../../models/Customer";
import useFetchForCustomer from "../swr/useSWRFetchForCustomer";

export const useDeviceDigitalSignage = (
  customer?: Customer,
  deviceId?: string
) => {
  const res = useFetchForCustomer(
    deviceId
      ? `/api/animations/bo/digitalsignage?deviceId=${deviceId}`
      : undefined,
    customer
  );
  return {
    ...res,
    currentAndNextDigitalSignageCampaigns:
      res.data as CurrentAndNextDigitalSignageCampaigns,
  };
};
