import TenantAdminTemplate from "../../components/layout/TenantAdminTemplate";
import Device from "../../models/Device";
import { EditPage } from "../../resources/components/EditPage";
import { DeviceAnimationDetails } from "./components/DeviceAnimationDetails";
import EditDeviceForm from "./components/EditDeviceForm";

const EditDevicePage = () => {
  return (
    <EditPage<Device>>
      <EditDeviceForm mode="edit" />
      <TenantAdminTemplate>
        <DeviceAnimationDetails />
      </TenantAdminTemplate>
    </EditPage>
  );
};

export default EditDevicePage;
