import moment from "moment";
import { useEffect, useMemo } from "react";
import Alert from "react-bootstrap/esm/Alert";
import Form from "react-bootstrap/esm/Form";
import FormGroup from "react-bootstrap/esm/FormGroup";
import { Controller, useFormContext } from "react-hook-form";
import Input from "../../../components/Input";
import { ControlledAnimationGroupsSelect } from "../../../components/selects/AnimationGroupsSelect";
import { ControlledStoresSelect } from "../../../components/selects/StoresSelect";
import Campaign, {
  CampaignPeriodType,
  CampaignTargetType,
} from "../../../models/Campaign";
import { ResourceHiddenNumberInput } from "../../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceInput } from "../../../resources/components/inputs/ResourceInput";
import { getISO8601Date } from "../../../tools/dateUtils";
import { ExtendedCampaign } from "../models/ExtendedCampaign";

const EditOrCreateCampaign = () => {
  const {
    register,
    formState: { errors, isSubmitting, isDirty },
    control,
    setValue,
    watch,
    getValues,
  } = useFormContext<ExtendedCampaign>();
  const r = watch();
  // log
  useEffect(() => {
    console.log("r", r);
  }, [r]);

  const targetType = watch("targetType");
  const periodType = watch("periodType");
  // log
  useEffect(() => {
    console.log("periodType", periodType);
  }, [periodType]);
  const isDraft = watch("isDraft");

  const campaignIsDraft = useMemo(() => {
    return isDraft && (JSON.parse(isDraft.toString()) as boolean);
  }, [isDraft]);

  useEffect(() => {
    register("targetAnimationGroupId", { valueAsNumber: true });
    register("targetStoreId", { valueAsNumber: true });
    register("isDraft");
  }, [register]);

  useEffect(() => {
    if (!isDirty) {
      return;
    }
    if (periodType === "permanent") {
      setValue("startDate", "");
      setValue("endDate", "");
    }
  }, [isDirty, periodType, setValue]);

  useEffect(() => {
    if (!isDirty) {
      return;
    }
    if (targetType === "all") {
      setValue("targetAnimationGroupId", undefined);
      setValue("targetStoreId", undefined);
    } else {
      if (targetType === "store") {
        setValue("targetAnimationGroupId", undefined);
      } else {
        if (targetType === "animation-group") {
          setValue("targetStoreId", undefined);
        }
      }
    }
  }, [isDirty, setValue, targetType]);

  return (
    <>
      <ResourceHiddenNumberInput name="id" />
      {campaignIsDraft && (
        <Alert variant="warning" role="alert">
          Brouillon
        </Alert>
      )}

      <ResourceInput
        label="Nom de la campagne*"
        name="label"
        options={{
          required: "Le nom de la campagne est obligatoire",
          maxLength: {
            value: 50,
            message: "Le nom de la campagne ne doit pas dépasser 50 caractères",
          },
        }}
      />
      <FormGroup>
        <Form.Label>Période*</Form.Label>
        <div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              {...register("periodType", {
                required: true,
              })}
              type="radio"
              id="periodType1"
              // checked={isPeriodTypePermanent}
              value={"permanent" as CampaignPeriodType}
            />
            <label className="form-check-label" htmlFor="periodType1">
              Permanente
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              {...register("periodType", {
                required: true,
              })}
              type="radio"
              // checked={isPeriodTypePlanified}
              id="periodType2"
              value={"planified" as CampaignPeriodType}
            />
            <label className="form-check-label" htmlFor="periodType2">
              Planifiée
            </label>
          </div>
        </div>
      </FormGroup>
      {periodType != null && periodType === "planified" && (
        <>
          <Controller
            name="startDate"
            defaultValue=""
            shouldUnregister={true}
            control={control}
            rules={{
              required: true,
              validate: (value) =>
                moment(value) <= moment(getValues("endDate")),
            }}
            render={({ field }) => (
              <Input
                {...field}
                label="Date de début*"
                type="date"
                value={field.value ? getISO8601Date(field.value) : ""}
                errorMessage={
                  errors.startDate && errors.startDate.type === "required"
                    ? "La date de début est obligatoire"
                    : "La date de début doit être inférieure à la date de fin"
                }
                isInvalid={errors.startDate !== undefined}
                disabled={isSubmitting}
                onClear={() => field.onChange("")}
              />
            )}
          />
          <Controller
            name="endDate"
            defaultValue=""
            shouldUnregister={true}
            rules={{
              required: true,
              validate: (value) =>
                moment(getValues("startDate")) <= moment(value),
            }}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label="Date de fin*"
                type="date"
                value={field.value ? getISO8601Date(field.value) : ""}
                errorMessage={
                  errors.endDate && errors.endDate.type === "required"
                    ? "La date de fin est obligatoire"
                    : "La date de début doit être inférieure à la date de fin"
                }
                isInvalid={errors.endDate !== undefined}
                disabled={isSubmitting}
                onClear={() => field.onChange("")}
              />
            )}
          />
        </>
      )}
      {(periodType == null || periodType === "permanent") && (
        <>
          <ResourceHiddenNumberInput name="startDate" />
          <ResourceHiddenNumberInput name="endDate" />
        </>
      )}

      <FormGroup>
        <Form.Label>Cible*</Form.Label>
        <div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              {...register("targetType", {
                required: true,
              })}
              type="radio"
              id="targetType1"
              value={"all" as CampaignTargetType}
            />
            <label className="form-check-label" htmlFor="targetType1">
              Tous les périphériques
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              {...register("targetType", {
                required: true,
              })}
              type="radio"
              id="targetType2"
              value={"store" as CampaignTargetType}
            />
            <label className="form-check-label" htmlFor="targetType2">
              Magasin
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              {...register("targetType", {
                required: true,
              })}
              type="radio"
              id="targetType3"
              value={"animation-group" as CampaignTargetType}
            />
            <label className="form-check-label" htmlFor="targetType3">
              Groupe d'animation
            </label>
          </div>
        </div>
        {targetType === "store" && (
          <ControlledStoresSelect<Campaign> name="targetStoreId" />
        )}
        {targetType === "animation-group" && (
          <ControlledAnimationGroupsSelect<Campaign> name="targetAnimationGroupId" />
        )}
      </FormGroup>
    </>
  );
};
export default EditOrCreateCampaign;
