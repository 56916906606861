import { DeviceAnimations } from "../../models/DeviceAnimations";
import Customer from "../../models/Customer";
import useFetchForCustomer from "../swr/useSWRFetchForCustomer";

export const useDeviceAnimations = (customer?: Customer, deviceId?: string) => {
  const res = useFetchForCustomer(
    deviceId ? `/api/animations/Detail?deviceId=${deviceId}` : undefined,
    customer
  );
  const d = res.data as DeviceAnimations;
  return { ...res, animationsDetail: d };
};
