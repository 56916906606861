import { CollapseDisplayGroup } from "../../../components/CollapseDisplayGroup";
import { DisplayGroup } from "../../../components/DisplayGroup";
import { CustomerLink } from "../../../components/navigation/CustomerLink";
import { Stack, StackDirection } from "../../../components/Stack";
import { useDeviceAnimations } from "../../../hooks/animations/useDeviceAnimations";
import { useDeviceDigitalSignage } from "../../../hooks/animations/useDeviceDigitalSignage";
import { useCurrentCustomer } from "../../../LoggedInBackOffice";
import Device from "../../../models/Device";
import { useResourceContext } from "../../../resources/hooks/useResourceContext";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../../resources/types/resourceName";

type DeviceAnimationDetailsProps = {};
export const DeviceAnimationDetails: React.FC<
  DeviceAnimationDetailsProps
> = ({}) => {
  const currentCustomer = useCurrentCustomer();

  const { resource: device } = useResourceContext<Device>();

  const {
    resourceUrlPathResolver: { edit },
  } = useResourceUrlPathResolver<ResourceName>();

  // useDeviceDigitalSignage
  const { currentAndNextDigitalSignageCampaigns } = useDeviceDigitalSignage(
    currentCustomer,
    device?.id
  );

  const current = currentAndNextDigitalSignageCampaigns?.current;
  const next = currentAndNextDigitalSignageCampaigns?.next;

  // useDeviceAnimationDetails
  const { animationsDetail } = useDeviceAnimations(currentCustomer, device?.id);

  return (
    <Stack>
      <CollapseDisplayGroup label="Campagne Digital Signage en cours et suivante">
        <DisplayGroup>
          {/* affichage de la campagne Digital Signage en cours  */}
          <p>
            <span>Campagne en cours : </span>
            {current ? (
              <CustomerLink
                to={edit("animations/digital-signage-campaigns", current.id)}
              >
                {current.label}
              </CustomerLink>
            ) : (
              <span>Aucune campagne en cours</span>
            )}
          </p>
        </DisplayGroup>
        <>
          <DisplayGroup>
            {/* affichage de la campagne Digital Signage suivante  */}
            <p>
              <span>Campagne suivante : </span>
              {next ? (
                <CustomerLink
                  to={edit("animations/digital-signage-campaigns", next.id)}
                >
                  {next.label}
                </CustomerLink>
              ) : (
                <span>Aucune campagne suivante</span>
              )}
            </p>
          </DisplayGroup>
        </>
      </CollapseDisplayGroup>

      <CollapseDisplayGroup label="Animations en cours" canHide={false}>
        {animationsDetail && (
          <>
            {/* affichage de la campagne Home Panels en cours  */}
            <DisplayGroup>
              <p>
                <span>Campagne de Home Panels : </span>
                {animationsDetail.homePanelsCampaign ? (
                  <CustomerLink
                    to={edit(
                      "animations/home-panels-campaigns",
                      animationsDetail.homePanelsCampaign.id
                    )}
                  >
                    {animationsDetail.homePanelsCampaign.label}
                  </CustomerLink>
                ) : (
                  <span>Aucune campagne en cours</span>
                )}
              </p>
            </DisplayGroup>

            {/* affichage de la campagne Home Ads en cours  */}
            <DisplayGroup>
              <p>
                <span>Campagne de Home Ads : </span>
                {animationsDetail.homeAdsCampaign ? (
                  <CustomerLink
                    to={edit(
                      "animations/home-ads-campaigns",
                      animationsDetail.homeAdsCampaign.id
                    )}
                  >
                    {animationsDetail.homeAdsCampaign.label}
                  </CustomerLink>
                ) : (
                  <span>Aucune campagne en cours</span>
                )}
              </p>
            </DisplayGroup>

            {/* affichage de la campagne Home Catalog en cours  */}
            <DisplayGroup>
              <p>
                <span>Campagne de Shop in Shop : </span>
                {animationsDetail.homeCatalogCampaign ? (
                  <CustomerLink
                    to={edit(
                      "animations/home-catalogs-campaigns",
                      animationsDetail.homeCatalogCampaign.id
                    )}
                  >
                    {animationsDetail.homeCatalogCampaign.label}
                  </CustomerLink>
                ) : (
                  <span>Aucune campagne en cours</span>
                )}
              </p>
            </DisplayGroup>
          </>
        )}
      </CollapseDisplayGroup>
    </Stack>
  );
};
