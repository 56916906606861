import { PropsWithChildren } from "react";

export enum StackDirection {
  Column,
  Row,
}
type StackProps = {
  direction?: StackDirection;
  gap?: 1 | 2 | 3 | 4 | 5;
};

export const Stack = ({
  children,
  direction = StackDirection.Column,
  gap = 2,
}: PropsWithChildren<StackProps>) => (
  <div
    className={`d-flex flex-${
      direction === StackDirection.Column ? "column" : "row"
    } gap-${gap}`}
  >
    {children}
  </div>
);
